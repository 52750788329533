.gg-container {
  margin-top: 20vh;
  position: absolute;
  top: 15%;
  left: 50%;
  transform: translateX(-50%);
  width: 90%;
  --main-color: #000;
  --secondary-color: #111;
  --txt-color: #fff;
  --img-bg-color: rgba(240, 240, 240, 0.9);
  --backdrop-color: rgba(240, 240, 240, 0.9);
  --gap-length: 50px;
  --row-height: 300px;
  --column-width: 400px;
  overflow-x: hidden;
}

.gg-container *[data-theme="dark"] {
  --main-color: #ddd;
  --secondary-color: #eee;
  --txt-color: #111;
  --img-bg-color: rgba(20, 20, 20, 0.9);
  --backdrop-color: rgba(30, 30, 30, 0.9);
}

.gg-box {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(var(--column-width), 1fr));
  grid-auto-rows: var(--row-height);
  grid-gap: var(--gap-length);
  margin: 20px 0;
}

.gg-box img {
  object-fit: cover;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  transition: .2s;
}

.gg-box img:hover {
  filter: invert(.3)
}

@media (min-width: 478px) {
  .gg-box img:nth-child(2n):not(:last-of-type) {
    grid-row-end: span 2;
  }

  [data-layout="horizontal"] img:nth-child(2n):not(:last-of-type) {
    grid-column-end: span 2;
    grid-row-end: span 1;
  }

  [data-layout="square"] img:nth-child(2n):not(:last-of-type) {
    grid-row-end: span 1;
    grid-column-end: span 1;
  }
}

@media only screen and (max-width: 600px) {
  .gg-box {
    display: flex;
    flex-direction: column;
    margin-bottom: 10vh;
  }

  .gg-box img:hover {
    filter: none;
  }
}