/*GENERAL*/

body {
  font-family: "orpheuspro";
  width: 100%;
  height: 100dvh;
}

#root {
  width: 100%;
  height: 100dvh;
}

#Home {
  overflow: hidden;
}

.section {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100dvh;
  z-index: 1;
}

#Gallery {
  height: auto;
}

/*NAVBAR*/

#navBarHome {
  position: absolute;
  top: 10px;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 90%;
  height: 100px;
}

.linkNav {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 20vw;
  height: 40px;
  font-weight: 500;
}

.linkNav:hover {
  text-decoration: underline;
}

#navBarHome a {
  color: black;
  text-decoration: none;
  font-size: 20px;
  z-index: 2
}

#homeLogo {
  width: 300px;
  height: 150px;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  transition: .5s;
}

#homeLogo:hover {
  transform: scale(1.1);
}

#navBarMobile {
  display: none;
}

/*TALENT*/

#talentContainer {
  position: absolute;
  top: 30%;
  left: 50%;
  width: 75%;
  transform: translateX(-50%);
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
}

#talentTitle {
  color: black;
  transition: 1s cubic-bezier(.07, .77, .44, .99);
  font-size: 25px;
  font-style: normal;
}

#talent {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  align-items: center;
  transition: 1s cubic-bezier(.05, .7, .3, 1);
}

#talent h1 {
  cursor: pointer;
  font-weight: normal;
  flex: 0 0 15%;
  text-align: center;
  font-size: 30px;
  margin: 0;
}

#talent h1:hover {
  text-decoration: underline;
}

.divider {
  width: 2%;
  color: #00308F;
  font-weight: 700;
  text-align: center;
}

/*BRANDS*/

#brands {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 150px;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

#brandsTop {
  display: flex;
  width: 100%;
  height: 50%;
}

#brandsBottom {
  display: flex;
  width: 100%;
  height: 50%;
}

.ticker-track-top {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: unset;
  flex-grow: 0;
  flex-shrink: 0;
}

.brandsAnimationTop {
  animation: ticker 30s linear infinite;
  -webkit-animation: ticker 30s linear infinite;
}

.ticker-track-bottom {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: unset;
  flex-grow: 0;
  flex-shrink: 0;
}

.brandsAnimationBottom {
  animation: ticker 30s linear infinite reverse;
  -webkit-animation: ticker 30s linear infinite reverse;
}

.ticker-img {
  width: auto;
  height: 40%;
  padding: 0 50px;
}

@keyframes ticker {
  0% {
    transform: translateX(0%);
  }

  100% {
    transform: translateX(-100%);
  }
}

@-webkit-keyframes ticker {
  0% {
    transform: translateX(0%);
  }

  100% {
    transform: translateX(-100%);
  }
}

/*SPOTIFY RADIO*/

#spotifyContainer {
  position: absolute;
  right: 0;
  bottom: -390px;
  width: 20%;
  background-color: black;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  transition: .75s;
  color: white;
}

#spotifyOpen {
  display: flex;
  width: 100%;
  height: 75px;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  font-style: normal;
}

#spotifyOpen h1 {
  font-size: 1.3vw;
}

#spotifyArrow {
  width: auto;
  height: 1.3vw;
  margin-right: 10px;
  filter: grayscale(100%) brightness(5)
}

#spotifyIntegration {
  background-color: #535353;
}

/*ABOUT*/

#aboutText {
  z-index: 2;
  position: absolute;
  text-align: justify;
  text-align-last: center;
  left: 50%;
  top: 42%;
  transform: translate(-50%, -50%);
  color: black;
  width: 40%;
  font-size: 22px;
  line-height: 28px;
}

#contactInfo {
  z-index: 2;
  position: absolute;
  top: 57%;
  left: 50%;
  width: 40%;
  transform: translateX(-50%);
  display: flex;
  justify-content: space-evenly;
}

.contacts {
  width: 5vw;
  height: 5vh;
  color: black;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 700;
  font-style: normal;
}

.aboutIcon {
  height: 100%;
  width: auto;
  cursor: pointer;
  opacity: 1;
  filter: grayscale(100%) brightness(0);
}

.aboutIcon:hover {
  opacity: .5;
}

.credits {
  z-index: 2;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  position: absolute;
  color: black;
  font-size: 12px;
}

.credits a {
  color: black;
  font-weight: 700;
  font-style: normal;
  text-decoration: none;
}

.credits a:hover {
  text-decoration: underline;
}

/*GALLERY*/

#cursor {
  position: absolute;
  transform: translate(-50%, -50%);
  color: white;
  font-style: normal;
  font-size: 20px;
}

.imageText {
  display: none;
}

/*TALENTPAGE*/

#talentName {
  text-align: center;
  font-weight: 700;
  font-style: normal;
  margin: 0;
}

#talentInfo {
  position: absolute;
  top: 20%;
  left: 50%;
  transform: translateX(-50%);
  width: 90%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

#talentText {
  width: 80%;
  text-align: center;
  font-size: 18px;
  font-style: normal;
}

#talentImages {
  height: 75%;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
}

.talentImage {
  height: 25vw;
  width: auto;
  margin: 0;
  margin: 20px 0;
}

#talentContacts {
  text-align: center;
}

#talentContacts span {
  color: red;
}

.talentContact {
  color: black;
  cursor: pointer;
  text-decoration: none;
  font-size: 20px;
}

.talentContact:hover {
  text-decoration: underline;
}

/*BUTTONS*/

#buttons {
  color: white;
  mix-blend-mode: difference;
}

#fontChanger {
  position: fixed;
  left: 10px;
  cursor: pointer;
}

#fontChanger p {
  display: inline-block;
  margin: 0px 10px;
}

#fontChanger p:hover {
  text-decoration: underline;
}

.slidecontainer {
  position: fixed;
  right: 10px;
  width: 150px;
}

#removeBackground {
  position: fixed;
  right: 60px;
  text-align: center;
  cursor: pointer;
}

#removeBackground:hover {
  text-decoration: underline;
}

@media only screen and (max-width: 600px) {

  /*NAVBAR*/

  .linkNav {
    display: none;
  }

  #navBarHome {
    position: fixed;
    top: 10px;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 90%;
    height: 100px;
  }

  #homeLogo {
    width: 75vw;
    height: 15vh;
  }

  #navBarMobile {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 80%;
    padding: 0 10% 0 10%;
    height: 8vh;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: black;
  }

  .linkNavMobile {
    font-size: 25px;
    color: white;
    text-decoration: underline;
  }

  #linkSpotifyMobile {
    height: 4vh;
    width: 25%;
    position: fixed;
    bottom: 2vh;
    left: 50%;
    transform: translateX(-50%);
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
  }

  /*TALENT*/

  #talentTitle {
    font-size: 5vw;
  }

  #talentContainer {
    top: 20%;
    width: 90%;
  }

  #talent {
    margin-top: 3vh;
    row-gap: 20px;
  }

  #talent h1 {
    flex: 0 0 30%;
    font-size: 6vw;
  }

  .divider {
    width: 3%;
  }

  /*BRANDS*/

  #brands {
    position: absolute;
    bottom: 10vh;
    left: 0;
    width: 100%;
    height: 20vh;
    display: flex;
    flex-direction: column;
    overflow: hidden;
  }

  .ticker-img {
    height: 30%;
  }

  /*GALLERY*/

  #cursor {
    display: none;
  }

  .imageText {
    display: block;
    width: 90%;
    margin: 5px 2.5% 5px 2.5%;
    text-align: center;
  }

  /*SPOTIFY RADIO*/

  #spotifyContainer {
    display: none;
  }

  /*ABOUT*/

  #aboutText {
    width: 80%;
  }

  #contactInfo {
    margin-top: 30px;
    width: 90%;
  }

  .credits {
    bottom: 11vh;
    font-size: 14px;
  }

  /*TALENTPAGE*/

  #talentInfo {
    top: 15%;
  }

  #talentText {
    width: 90%;
    text-align: justify;
    text-align-last: center;
    hyphens: auto;
    margin-bottom: 20px;
  }

  #talentImages {
    width: 100%;
    margin-bottom: 10vh;
  }

  .talentImage {
    height: auto;
    width: 90vw;
  }

  .talentContact {
    font-size: 25px;
  }

  /*BUTTONS*/

  #buttons {
    display: none;
  }
}